import { PickingInfo } from "@deck.gl/core/typed";
import React, { FC } from "react";
import { AppConfig } from "../../../utils/appconfig";
import {
  EMPTY_FIELDS_DATA,
  GeodataLayerState,
  GeojsonLayerState,
  IconLayerState,
} from "./reducer";
import classNames from "classnames";

interface GeoDataLayerTooltipProps {
  info: PickingInfo;
  appConfig: AppConfig;
  layer: GeodataLayerState;
}

export const GeoDataLayerTooltip: FC<GeoDataLayerTooltipProps> = ({
  info,
  appConfig,
  layer,
}) => {
  const { data, activeField } = layer;
  if (!data || !activeField) {
    return null;
  }
  const { object, x, y } = info;
  const { properties } = object as GeoJSON.Feature<GeoJSON.Geometry>;
  if (!properties) {
    return null;
  }
  let value: number;
  if (data.fields === EMPTY_FIELDS_DATA) {
    if (activeField in properties) {
      value = properties[activeField];
    } else {
      return null;
    }
  } else {
    const geoid = properties["geoid"];
    if (!geoid || !data.fields[geoid]) {
      return null;
    }
    value = data.fields[geoid][activeField];
  }
  const fieldName = appConfig.fields[activeField].name;
  const formatted = appConfig.fields[activeField].formatLong(value);

  return <BasicTooltip x={x} y={y} title={fieldName} content={formatted} />;
};

interface GeojsonLayerTooltipProps {
  info: PickingInfo;
  layer: GeojsonLayerState;
}

export const GeojsonLayerTooltip: FC<GeojsonLayerTooltipProps> = ({
  info,
  layer,
}) => {
  const { data, visible } = layer;
  if (!data || !visible) return null;
  const { object, x, y } = info;

  return (
    <BasicTooltip
      x={x}
      y={y}
      title={object.properties.title}
      content={object.properties.content}
    />
  );
};

interface IconLayerTooltipProps {
  info: PickingInfo;
  layer: IconLayerState;
}

export const IconLayerTooltip: FC<IconLayerTooltipProps> = ({
  info,
  layer,
}) => {
  const { visible } = layer;
  if (!visible) return null;
  const { object, x, y } = info;

  return (
    <BasicTooltip
      x={x}
      y={y}
      title={object["title"]}
      content={object["content"]}
    />
  );
};

interface BasicTooltipProps {
  x: number;
  y: number;
  title: string;
  content: string | string[] | JSX.Element;
}

const PANEL_WIDTH = 360;
const TOOLTIP_MARGIN = 8;

export const BasicTooltip: FC<BasicTooltipProps> = ({
  x,
  y,
  title,
  content,
}) => {
  const top = y + TOOLTIP_MARGIN;
  const left = x + TOOLTIP_MARGIN + PANEL_WIDTH;
  const isArrayContent = Array.isArray(content);
  return (
    <div
      className={classNames(
        "absolute z-20 pointer-events-none bg-gray-50 p-2 rounded-md shadow-md"
      )}
      style={{ top, left }}
    >
      <div className="font-bold">{title}</div>
      {isArrayContent ? (
        content.map((c, i) => (
          <div key={i} className="text-sm">
            {c}
          </div>
        ))
      ) : (
        <div>{content}</div>
      )}
    </div>
  );
};
