export { default as AcademicCapIcon } from './AcademicCapIcon.js'
export { default as AdjustmentsHorizontalIcon } from './AdjustmentsHorizontalIcon.js'
export { default as AdjustmentsVerticalIcon } from './AdjustmentsVerticalIcon.js'
export { default as ArchiveBoxArrowDownIcon } from './ArchiveBoxArrowDownIcon.js'
export { default as ArchiveBoxXMarkIcon } from './ArchiveBoxXMarkIcon.js'
export { default as ArchiveBoxIcon } from './ArchiveBoxIcon.js'
export { default as ArrowDownCircleIcon } from './ArrowDownCircleIcon.js'
export { default as ArrowDownLeftIcon } from './ArrowDownLeftIcon.js'
export { default as ArrowDownOnSquareStackIcon } from './ArrowDownOnSquareStackIcon.js'
export { default as ArrowDownOnSquareIcon } from './ArrowDownOnSquareIcon.js'
export { default as ArrowDownRightIcon } from './ArrowDownRightIcon.js'
export { default as ArrowDownTrayIcon } from './ArrowDownTrayIcon.js'
export { default as ArrowDownIcon } from './ArrowDownIcon.js'
export { default as ArrowLeftCircleIcon } from './ArrowLeftCircleIcon.js'
export { default as ArrowLeftOnRectangleIcon } from './ArrowLeftOnRectangleIcon.js'
export { default as ArrowLeftIcon } from './ArrowLeftIcon.js'
export { default as ArrowLongDownIcon } from './ArrowLongDownIcon.js'
export { default as ArrowLongLeftIcon } from './ArrowLongLeftIcon.js'
export { default as ArrowLongRightIcon } from './ArrowLongRightIcon.js'
export { default as ArrowLongUpIcon } from './ArrowLongUpIcon.js'
export { default as ArrowPathRoundedSquareIcon } from './ArrowPathRoundedSquareIcon.js'
export { default as ArrowPathIcon } from './ArrowPathIcon.js'
export { default as ArrowRightCircleIcon } from './ArrowRightCircleIcon.js'
export { default as ArrowRightOnRectangleIcon } from './ArrowRightOnRectangleIcon.js'
export { default as ArrowRightIcon } from './ArrowRightIcon.js'
export { default as ArrowSmallDownIcon } from './ArrowSmallDownIcon.js'
export { default as ArrowSmallLeftIcon } from './ArrowSmallLeftIcon.js'
export { default as ArrowSmallRightIcon } from './ArrowSmallRightIcon.js'
export { default as ArrowSmallUpIcon } from './ArrowSmallUpIcon.js'
export { default as ArrowTopRightOnSquareIcon } from './ArrowTopRightOnSquareIcon.js'
export { default as ArrowTrendingDownIcon } from './ArrowTrendingDownIcon.js'
export { default as ArrowTrendingUpIcon } from './ArrowTrendingUpIcon.js'
export { default as ArrowUpCircleIcon } from './ArrowUpCircleIcon.js'
export { default as ArrowUpLeftIcon } from './ArrowUpLeftIcon.js'
export { default as ArrowUpOnSquareStackIcon } from './ArrowUpOnSquareStackIcon.js'
export { default as ArrowUpOnSquareIcon } from './ArrowUpOnSquareIcon.js'
export { default as ArrowUpRightIcon } from './ArrowUpRightIcon.js'
export { default as ArrowUpTrayIcon } from './ArrowUpTrayIcon.js'
export { default as ArrowUpIcon } from './ArrowUpIcon.js'
export { default as ArrowUturnDownIcon } from './ArrowUturnDownIcon.js'
export { default as ArrowUturnLeftIcon } from './ArrowUturnLeftIcon.js'
export { default as ArrowUturnRightIcon } from './ArrowUturnRightIcon.js'
export { default as ArrowUturnUpIcon } from './ArrowUturnUpIcon.js'
export { default as ArrowsPointingInIcon } from './ArrowsPointingInIcon.js'
export { default as ArrowsPointingOutIcon } from './ArrowsPointingOutIcon.js'
export { default as ArrowsRightLeftIcon } from './ArrowsRightLeftIcon.js'
export { default as ArrowsUpDownIcon } from './ArrowsUpDownIcon.js'
export { default as AtSymbolIcon } from './AtSymbolIcon.js'
export { default as BackspaceIcon } from './BackspaceIcon.js'
export { default as BackwardIcon } from './BackwardIcon.js'
export { default as BanknotesIcon } from './BanknotesIcon.js'
export { default as Bars2Icon } from './Bars2Icon.js'
export { default as Bars3BottomLeftIcon } from './Bars3BottomLeftIcon.js'
export { default as Bars3BottomRightIcon } from './Bars3BottomRightIcon.js'
export { default as Bars3CenterLeftIcon } from './Bars3CenterLeftIcon.js'
export { default as Bars3Icon } from './Bars3Icon.js'
export { default as Bars4Icon } from './Bars4Icon.js'
export { default as BarsArrowDownIcon } from './BarsArrowDownIcon.js'
export { default as BarsArrowUpIcon } from './BarsArrowUpIcon.js'
export { default as Battery0Icon } from './Battery0Icon.js'
export { default as Battery100Icon } from './Battery100Icon.js'
export { default as Battery50Icon } from './Battery50Icon.js'
export { default as BeakerIcon } from './BeakerIcon.js'
export { default as BellAlertIcon } from './BellAlertIcon.js'
export { default as BellSlashIcon } from './BellSlashIcon.js'
export { default as BellSnoozeIcon } from './BellSnoozeIcon.js'
export { default as BellIcon } from './BellIcon.js'
export { default as BoltSlashIcon } from './BoltSlashIcon.js'
export { default as BoltIcon } from './BoltIcon.js'
export { default as BookOpenIcon } from './BookOpenIcon.js'
export { default as BookmarkSlashIcon } from './BookmarkSlashIcon.js'
export { default as BookmarkSquareIcon } from './BookmarkSquareIcon.js'
export { default as BookmarkIcon } from './BookmarkIcon.js'
export { default as BriefcaseIcon } from './BriefcaseIcon.js'
export { default as BugAntIcon } from './BugAntIcon.js'
export { default as BuildingLibraryIcon } from './BuildingLibraryIcon.js'
export { default as BuildingOffice2Icon } from './BuildingOffice2Icon.js'
export { default as BuildingOfficeIcon } from './BuildingOfficeIcon.js'
export { default as BuildingStorefrontIcon } from './BuildingStorefrontIcon.js'
export { default as CakeIcon } from './CakeIcon.js'
export { default as CalculatorIcon } from './CalculatorIcon.js'
export { default as CalendarDaysIcon } from './CalendarDaysIcon.js'
export { default as CalendarIcon } from './CalendarIcon.js'
export { default as CameraIcon } from './CameraIcon.js'
export { default as ChartBarSquareIcon } from './ChartBarSquareIcon.js'
export { default as ChartBarIcon } from './ChartBarIcon.js'
export { default as ChartPieIcon } from './ChartPieIcon.js'
export { default as ChatBubbleBottomCenterTextIcon } from './ChatBubbleBottomCenterTextIcon.js'
export { default as ChatBubbleBottomCenterIcon } from './ChatBubbleBottomCenterIcon.js'
export { default as ChatBubbleLeftEllipsisIcon } from './ChatBubbleLeftEllipsisIcon.js'
export { default as ChatBubbleLeftRightIcon } from './ChatBubbleLeftRightIcon.js'
export { default as ChatBubbleLeftIcon } from './ChatBubbleLeftIcon.js'
export { default as ChatBubbleOvalLeftEllipsisIcon } from './ChatBubbleOvalLeftEllipsisIcon.js'
export { default as ChatBubbleOvalLeftIcon } from './ChatBubbleOvalLeftIcon.js'
export { default as CheckBadgeIcon } from './CheckBadgeIcon.js'
export { default as CheckCircleIcon } from './CheckCircleIcon.js'
export { default as CheckIcon } from './CheckIcon.js'
export { default as ChevronDoubleDownIcon } from './ChevronDoubleDownIcon.js'
export { default as ChevronDoubleLeftIcon } from './ChevronDoubleLeftIcon.js'
export { default as ChevronDoubleRightIcon } from './ChevronDoubleRightIcon.js'
export { default as ChevronDoubleUpIcon } from './ChevronDoubleUpIcon.js'
export { default as ChevronDownIcon } from './ChevronDownIcon.js'
export { default as ChevronLeftIcon } from './ChevronLeftIcon.js'
export { default as ChevronRightIcon } from './ChevronRightIcon.js'
export { default as ChevronUpDownIcon } from './ChevronUpDownIcon.js'
export { default as ChevronUpIcon } from './ChevronUpIcon.js'
export { default as CircleStackIcon } from './CircleStackIcon.js'
export { default as ClipboardDocumentCheckIcon } from './ClipboardDocumentCheckIcon.js'
export { default as ClipboardDocumentListIcon } from './ClipboardDocumentListIcon.js'
export { default as ClipboardDocumentIcon } from './ClipboardDocumentIcon.js'
export { default as ClipboardIcon } from './ClipboardIcon.js'
export { default as ClockIcon } from './ClockIcon.js'
export { default as CloudArrowDownIcon } from './CloudArrowDownIcon.js'
export { default as CloudArrowUpIcon } from './CloudArrowUpIcon.js'
export { default as CloudIcon } from './CloudIcon.js'
export { default as CodeBracketSquareIcon } from './CodeBracketSquareIcon.js'
export { default as CodeBracketIcon } from './CodeBracketIcon.js'
export { default as Cog6ToothIcon } from './Cog6ToothIcon.js'
export { default as Cog8ToothIcon } from './Cog8ToothIcon.js'
export { default as CogIcon } from './CogIcon.js'
export { default as CommandLineIcon } from './CommandLineIcon.js'
export { default as ComputerDesktopIcon } from './ComputerDesktopIcon.js'
export { default as CpuChipIcon } from './CpuChipIcon.js'
export { default as CreditCardIcon } from './CreditCardIcon.js'
export { default as CubeTransparentIcon } from './CubeTransparentIcon.js'
export { default as CubeIcon } from './CubeIcon.js'
export { default as CurrencyBangladeshiIcon } from './CurrencyBangladeshiIcon.js'
export { default as CurrencyDollarIcon } from './CurrencyDollarIcon.js'
export { default as CurrencyEuroIcon } from './CurrencyEuroIcon.js'
export { default as CurrencyPoundIcon } from './CurrencyPoundIcon.js'
export { default as CurrencyRupeeIcon } from './CurrencyRupeeIcon.js'
export { default as CurrencyYenIcon } from './CurrencyYenIcon.js'
export { default as CursorArrowRaysIcon } from './CursorArrowRaysIcon.js'
export { default as CursorArrowRippleIcon } from './CursorArrowRippleIcon.js'
export { default as DevicePhoneMobileIcon } from './DevicePhoneMobileIcon.js'
export { default as DeviceTabletIcon } from './DeviceTabletIcon.js'
export { default as DocumentArrowDownIcon } from './DocumentArrowDownIcon.js'
export { default as DocumentArrowUpIcon } from './DocumentArrowUpIcon.js'
export { default as DocumentChartBarIcon } from './DocumentChartBarIcon.js'
export { default as DocumentCheckIcon } from './DocumentCheckIcon.js'
export { default as DocumentDuplicateIcon } from './DocumentDuplicateIcon.js'
export { default as DocumentMagnifyingGlassIcon } from './DocumentMagnifyingGlassIcon.js'
export { default as DocumentMinusIcon } from './DocumentMinusIcon.js'
export { default as DocumentPlusIcon } from './DocumentPlusIcon.js'
export { default as DocumentTextIcon } from './DocumentTextIcon.js'
export { default as DocumentIcon } from './DocumentIcon.js'
export { default as EllipsisHorizontalCircleIcon } from './EllipsisHorizontalCircleIcon.js'
export { default as EllipsisHorizontalIcon } from './EllipsisHorizontalIcon.js'
export { default as EllipsisVerticalIcon } from './EllipsisVerticalIcon.js'
export { default as EnvelopeOpenIcon } from './EnvelopeOpenIcon.js'
export { default as EnvelopeIcon } from './EnvelopeIcon.js'
export { default as ExclamationCircleIcon } from './ExclamationCircleIcon.js'
export { default as ExclamationTriangleIcon } from './ExclamationTriangleIcon.js'
export { default as EyeDropperIcon } from './EyeDropperIcon.js'
export { default as EyeSlashIcon } from './EyeSlashIcon.js'
export { default as EyeIcon } from './EyeIcon.js'
export { default as FaceFrownIcon } from './FaceFrownIcon.js'
export { default as FaceSmileIcon } from './FaceSmileIcon.js'
export { default as FilmIcon } from './FilmIcon.js'
export { default as FingerPrintIcon } from './FingerPrintIcon.js'
export { default as FireIcon } from './FireIcon.js'
export { default as FlagIcon } from './FlagIcon.js'
export { default as FolderArrowDownIcon } from './FolderArrowDownIcon.js'
export { default as FolderMinusIcon } from './FolderMinusIcon.js'
export { default as FolderOpenIcon } from './FolderOpenIcon.js'
export { default as FolderPlusIcon } from './FolderPlusIcon.js'
export { default as FolderIcon } from './FolderIcon.js'
export { default as ForwardIcon } from './ForwardIcon.js'
export { default as FunnelIcon } from './FunnelIcon.js'
export { default as GifIcon } from './GifIcon.js'
export { default as GiftTopIcon } from './GiftTopIcon.js'
export { default as GiftIcon } from './GiftIcon.js'
export { default as GlobeAltIcon } from './GlobeAltIcon.js'
export { default as GlobeAmericasIcon } from './GlobeAmericasIcon.js'
export { default as GlobeAsiaAustraliaIcon } from './GlobeAsiaAustraliaIcon.js'
export { default as GlobeEuropeAfricaIcon } from './GlobeEuropeAfricaIcon.js'
export { default as HandRaisedIcon } from './HandRaisedIcon.js'
export { default as HandThumbDownIcon } from './HandThumbDownIcon.js'
export { default as HandThumbUpIcon } from './HandThumbUpIcon.js'
export { default as HashtagIcon } from './HashtagIcon.js'
export { default as HeartIcon } from './HeartIcon.js'
export { default as HomeModernIcon } from './HomeModernIcon.js'
export { default as HomeIcon } from './HomeIcon.js'
export { default as IdentificationIcon } from './IdentificationIcon.js'
export { default as InboxArrowDownIcon } from './InboxArrowDownIcon.js'
export { default as InboxStackIcon } from './InboxStackIcon.js'
export { default as InboxIcon } from './InboxIcon.js'
export { default as InformationCircleIcon } from './InformationCircleIcon.js'
export { default as KeyIcon } from './KeyIcon.js'
export { default as LanguageIcon } from './LanguageIcon.js'
export { default as LifebuoyIcon } from './LifebuoyIcon.js'
export { default as LightBulbIcon } from './LightBulbIcon.js'
export { default as LinkIcon } from './LinkIcon.js'
export { default as ListBulletIcon } from './ListBulletIcon.js'
export { default as LockClosedIcon } from './LockClosedIcon.js'
export { default as LockOpenIcon } from './LockOpenIcon.js'
export { default as MagnifyingGlassCircleIcon } from './MagnifyingGlassCircleIcon.js'
export { default as MagnifyingGlassMinusIcon } from './MagnifyingGlassMinusIcon.js'
export { default as MagnifyingGlassPlusIcon } from './MagnifyingGlassPlusIcon.js'
export { default as MagnifyingGlassIcon } from './MagnifyingGlassIcon.js'
export { default as MapPinIcon } from './MapPinIcon.js'
export { default as MapIcon } from './MapIcon.js'
export { default as MegaphoneIcon } from './MegaphoneIcon.js'
export { default as MicrophoneIcon } from './MicrophoneIcon.js'
export { default as MinusCircleIcon } from './MinusCircleIcon.js'
export { default as MinusSmallIcon } from './MinusSmallIcon.js'
export { default as MinusIcon } from './MinusIcon.js'
export { default as MoonIcon } from './MoonIcon.js'
export { default as MusicalNoteIcon } from './MusicalNoteIcon.js'
export { default as NewspaperIcon } from './NewspaperIcon.js'
export { default as NoSymbolIcon } from './NoSymbolIcon.js'
export { default as PaintBrushIcon } from './PaintBrushIcon.js'
export { default as PaperAirplaneIcon } from './PaperAirplaneIcon.js'
export { default as PaperClipIcon } from './PaperClipIcon.js'
export { default as PauseCircleIcon } from './PauseCircleIcon.js'
export { default as PauseIcon } from './PauseIcon.js'
export { default as PencilSquareIcon } from './PencilSquareIcon.js'
export { default as PencilIcon } from './PencilIcon.js'
export { default as PhoneArrowDownLeftIcon } from './PhoneArrowDownLeftIcon.js'
export { default as PhoneArrowUpRightIcon } from './PhoneArrowUpRightIcon.js'
export { default as PhoneXMarkIcon } from './PhoneXMarkIcon.js'
export { default as PhoneIcon } from './PhoneIcon.js'
export { default as PhotoIcon } from './PhotoIcon.js'
export { default as PlayCircleIcon } from './PlayCircleIcon.js'
export { default as PlayPauseIcon } from './PlayPauseIcon.js'
export { default as PlayIcon } from './PlayIcon.js'
export { default as PlusCircleIcon } from './PlusCircleIcon.js'
export { default as PlusSmallIcon } from './PlusSmallIcon.js'
export { default as PlusIcon } from './PlusIcon.js'
export { default as PowerIcon } from './PowerIcon.js'
export { default as PresentationChartBarIcon } from './PresentationChartBarIcon.js'
export { default as PresentationChartLineIcon } from './PresentationChartLineIcon.js'
export { default as PrinterIcon } from './PrinterIcon.js'
export { default as PuzzlePieceIcon } from './PuzzlePieceIcon.js'
export { default as QrCodeIcon } from './QrCodeIcon.js'
export { default as QuestionMarkCircleIcon } from './QuestionMarkCircleIcon.js'
export { default as QueueListIcon } from './QueueListIcon.js'
export { default as RadioIcon } from './RadioIcon.js'
export { default as ReceiptPercentIcon } from './ReceiptPercentIcon.js'
export { default as ReceiptRefundIcon } from './ReceiptRefundIcon.js'
export { default as RectangleGroupIcon } from './RectangleGroupIcon.js'
export { default as RectangleStackIcon } from './RectangleStackIcon.js'
export { default as RocketLaunchIcon } from './RocketLaunchIcon.js'
export { default as RssIcon } from './RssIcon.js'
export { default as ScaleIcon } from './ScaleIcon.js'
export { default as ScissorsIcon } from './ScissorsIcon.js'
export { default as ServerStackIcon } from './ServerStackIcon.js'
export { default as ServerIcon } from './ServerIcon.js'
export { default as ShareIcon } from './ShareIcon.js'
export { default as ShieldCheckIcon } from './ShieldCheckIcon.js'
export { default as ShieldExclamationIcon } from './ShieldExclamationIcon.js'
export { default as ShoppingBagIcon } from './ShoppingBagIcon.js'
export { default as ShoppingCartIcon } from './ShoppingCartIcon.js'
export { default as SignalSlashIcon } from './SignalSlashIcon.js'
export { default as SignalIcon } from './SignalIcon.js'
export { default as SparklesIcon } from './SparklesIcon.js'
export { default as SpeakerWaveIcon } from './SpeakerWaveIcon.js'
export { default as SpeakerXMarkIcon } from './SpeakerXMarkIcon.js'
export { default as Square2StackIcon } from './Square2StackIcon.js'
export { default as Square3Stack3DIcon } from './Square3Stack3DIcon.js'
export { default as Squares2X2Icon } from './Squares2X2Icon.js'
export { default as SquaresPlusIcon } from './SquaresPlusIcon.js'
export { default as StarIcon } from './StarIcon.js'
export { default as StopCircleIcon } from './StopCircleIcon.js'
export { default as StopIcon } from './StopIcon.js'
export { default as SunIcon } from './SunIcon.js'
export { default as SwatchIcon } from './SwatchIcon.js'
export { default as TableCellsIcon } from './TableCellsIcon.js'
export { default as TagIcon } from './TagIcon.js'
export { default as TicketIcon } from './TicketIcon.js'
export { default as TrashIcon } from './TrashIcon.js'
export { default as TrophyIcon } from './TrophyIcon.js'
export { default as TruckIcon } from './TruckIcon.js'
export { default as TvIcon } from './TvIcon.js'
export { default as UserCircleIcon } from './UserCircleIcon.js'
export { default as UserGroupIcon } from './UserGroupIcon.js'
export { default as UserMinusIcon } from './UserMinusIcon.js'
export { default as UserPlusIcon } from './UserPlusIcon.js'
export { default as UserIcon } from './UserIcon.js'
export { default as UsersIcon } from './UsersIcon.js'
export { default as VariableIcon } from './VariableIcon.js'
export { default as VideoCameraSlashIcon } from './VideoCameraSlashIcon.js'
export { default as VideoCameraIcon } from './VideoCameraIcon.js'
export { default as ViewColumnsIcon } from './ViewColumnsIcon.js'
export { default as ViewfinderCircleIcon } from './ViewfinderCircleIcon.js'
export { default as WalletIcon } from './WalletIcon.js'
export { default as WifiIcon } from './WifiIcon.js'
export { default as WindowIcon } from './WindowIcon.js'
export { default as WrenchScrewdriverIcon } from './WrenchScrewdriverIcon.js'
export { default as WrenchIcon } from './WrenchIcon.js'
export { default as XCircleIcon } from './XCircleIcon.js'
export { default as XMarkIcon } from './XMarkIcon.js'